import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '../../utils/useDeviceDetect';

import Button from '../Button';
import checkCircleIcon from '../../images/icons/checkCircle.svg';

const Service = ({
  image,
  imageAlt,
  title,
  descriptions,
  link,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  return (
    <div className={`_service-wrapper`}>
      <img src={image} className={`_service-image`} alt={imageAlt}/>
      <div className={
        `${isMobile ? 'text-mobile-h3' : 'text-web-h2'} _service-title`
      }>
        {title}
      </div>
      <div className={`_service-description-wrapper`}>
        {
          descriptions.map((description, index) => (
            <div key={index} className={
              `${isMobile ? 'text-mobile-paragraph-small' : 'text-web-label-input'} _service-description`
            }>
              <img src={checkCircleIcon} className={`_service-icon`} alt=''/>
              <div>{description}</div>
            </div>
          ))
        }
      </div>
      <div className={`_service-actionButton`}>
        <Button to={link} filled={true}>{t('services.sections.services.actionButton')}</Button>
      </div>
    </div>
  )
};

export default Service;
