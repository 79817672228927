import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';

import Navbar from '../components/Navbar';
import PageHeader from '../components/PageHeader';
import '../styles/index.scss';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import List from '../components/List';
import Service from "../components/Service";
import industryIllustration from '../images/illustrations/industry.svg';
import noIndustryIllustration from '../images/illustrations/noIndustry.svg';
import Spacer from '../components/Spacer/index';
import useDeviceDetect from '../utils/useDeviceDetect';
import repsolLogo from '../images/pictures/repsolLogo.svg';
import ContactSection from "../components/ContactSection";
import InterestSection from '../components/InterestSection';
import Footer from '../components/Footer';
import getMetadata from '../utils/getMetadata';
import FloatButton from "../components/FloatButton";
import mailWhiteIcon from '../images/icons/mailWhite.svg'; 
import wppWhiteIcon from '../images/icons/wppWhite.svg'; 

const Services = ({ pageContext, location }) => {
  const { lang } = pageContext;
  const { t, i18n } = useTranslation();
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [])

  return (
    <>
      {
        getMetadata({
          pageName: 'services',
          link: 'services',
          lang,
          t,
        })
      }
      <Navbar location={location}/>
      <FloatButton
        image={mailWhiteIcon}
        imageAlt={t('floatButton.contact.imageAlt')}
        link={`/${lang}/contact`}
        index={0}
      />
      <FloatButton
        image={wppWhiteIcon}
        imageAlt={t('floatButton.wpp.imageAlt')}
        link={`https://wa.me/51998785550?text=${encodeURIComponent(t('floatButton.wpp.text'))}`}
        index={1}
        isExternalLink={true}
      />
      <PageHeader
        title={t(`services.header.title`)}
        description={t(`services.header.description`)}
        photo='services'
        photoAlt={t('services.header.imageAlt')}
      />
      <Section
        title={t('services.sections.services.title')}
        isGray={true}
        items={[
          (
            <SectionTitle
              title={t('services.sections.services.subTitle')}
            />
          ),
          ( 
            <List
              items={[
                (
                  <Service
                    image={industryIllustration}
                    imageAlt={t('services.sections.services.industry.title')}
                    title={t('services.sections.services.industry.title')}
                    descriptions={t('services.sections.services.industry.description')}
                    link={`/${lang}/contact`}
                  />
                ),
                (
                  <Spacer vertical={!isMobile} />
                ),
                (
                  <Service
                    image={noIndustryIllustration}
                    imageAlt={t('services.sections.services.noIndustry.title')}
                    title={t('services.sections.services.noIndustry.title')}
                    descriptions={t('services.sections.services.noIndustry.description')}
                    link={`/${lang}/contact`}
                  />
                ),
              ]}
            />
          ),
        ]}
      />
      <Section
        title={t('services.sections.clients.title')}
        items={[
          (
            <SectionTitle
              title={t('services.sections.clients.subTitle')}
              description={t('services.sections.clients.descriptions')}
            />
          ),
          ( 
            <List
              items={[
                (
                  <img src={repsolLogo} alt={t('services.sections.clients.repsolLogoAlt')} style={{ width: '142px', marginTop: '40px' }}/>
                )
              ]}
            />
          ),
        ]}
      />
      <ContactSection
        mobileMainTitle={t('home.sections.contact.title')}
        title={t('home.sections.contact.subTitle')}
        description={t('home.sections.contact.description')}
        isGray={true}
      />
      <InterestSection
        name='aboutUs'
        link={`/${lang}/about`}
      />
      <Footer lang={lang}/>
    </>
  );
};

export default Services;
